/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #264653;
  --secondary-color: #2A9D8F;
  --tertiary-color: #A8DBDD;
  --contrast-color-1: #DDC958;
  --contrast-color-2: #F56262;
  --font-family: 'Red Hat Display', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--tertiary-color);
  color: var(--primary-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  font-family: var(--font-family);
}

a {
  color: var(--secondary-color);
  font-family: var(--font-family);
}

a:hover {
  color: var(--tertiary-color);
}

.main-content {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

@keyframes fly-in {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-fly-in {
  animation: fly-in 0.5s ease-out forwards;
}

/* Sidebar specific styles */
.sidebar-link {
  color: white;
}

.sidebar-link:hover {
  color: white; /* Ensure text color remains white on hover */
  background-color: rgba(var(--secondary-color-rgb), 0.5); /* Ensure background color is 50% opaque */
}

.sidebar-link:hover .group-hover\:text-white {
  color: white !important; /* Ensure the icon color remains white on hover */
}

.bg-secondary-color {
  background-color: var(--secondary-color) !important; /* Ensure solid secondary color background for active link */
}

.hover\:bg-opacity-50:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.5); /* 50% opaque secondary color on hover */
}

/* Mobile navigation specific styles */
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.mobile-nav .logo {
  height: 40px;
}

.mobile-nav .menu-icon {
  cursor: pointer;
  color: white;
}

.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding-top: 100px; /* To account for the navbar height */
}

.mobile-sidebar.open {
  transform: translateX(0);
}

.mobile-sidebar nav ul {
  padding-left: 20px; /* Adjust padding for mobile menu */
}

.mobile-sidebar nav li a {
  padding-left: 40px; /* Ensure padding aligns with the logo */
  padding: 12px;
  display: block;
  color: white;
}

.mobile-sidebar nav li a:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.5);
}

/* Adjustments for padding and alignment */
@media (min-width: 768px) {
  .main-content {
    padding-left: 0 !important; /* Remove left padding for main content on desktop */
  }
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 100px; /* Add top padding for main content on mobile */
  }
}

/* Custom button styles */
.btn-link {
  color: white;
  text-decoration: none;
}

.btn-link:hover {
  color: white;
}

.office-group {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.office-group h3 {
  margin-right: 1rem;
}

@media (min-width: 1200px) {
  .space-y-5 {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .space-y-5 > div {
    flex: 0 0 calc(50% - 1rem);
  }
}

.custom-slider::-webkit-slider-thumb {
  background: #2A9D8F !important;
}

.custom-range::-moz-range-thumb {
  background: #2A9D8F !important;
}

.custom-range {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(to right, 
    #e5e7eb 0%, #e5e7eb 50%, 
    #fee2e2 50%, #fee2e2 100%
  );
  outline: none;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2A9D8F !important;
  cursor: pointer;
  margin-top: -5px; /* Centers the thumb vertically */
}

.custom-range::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2A9D8F !important;
  cursor: pointer;
  margin-top: -5px; /* Centers the thumb vertically */
}

.custom-range::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 4px;
}

.custom-range::-moz-range-track {
  height: 8px;
  border-radius: 4px;
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-ping {
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes rotate360 {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.group:hover .group-hover\:rotate-360 {
  animation: rotate360 1s linear infinite;
}